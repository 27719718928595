<template>
  <div :class="`ql-header ql-header_${theme}`" :style="{ backgroundColor: bgColor }">
    <div class="ql-header-left">
      <slot name="logo">
        <div class="logo">
          <img class="cursor-pointer" :src="defaultLogoUrl" />
          <p class="T4B">后台管理</p>
        </div>
      </slot>
    </div>

    <div class="ql-header-right">
      <slot name="list"></slot>
      <slot name="user">
        <dropdown class="ql-header-user" ref="userDrop" :hide-timeout="250" hide-on-click>
          <span class="ql-header-user-dropdown" @click="handleClickRoute('userInfo')">
            <img class="ql-header-user-dropdown-avatar" :src="avatarUrl" @error="avatarUrl = defalutAvatarUrl" />
            <p class="mg-l-4">{{ userName }}</p>
            <i class="iconfont icon-ic_shouhui mg-l-4"></i>
          </span>
          <dropdown-menu slot="dropdown" class="ql-header-user-menu">
            <dropdown-item @click.native="handleClickRoute('user-info')">
              <div class="flex items-center">
                <i class="iconfont icon-a-yonghuwode"></i>
                <p>个人中心</p>
              </div>
            </dropdown-item>
            <dropdown-item class="dropdown-item_red" @click.native="handleClickRoute('logout')">
              <div class="flex items-center">
                <i class="iconfont icon-icon_tuichu"></i>
                <p>退出登录</p>
              </div>
            </dropdown-item>
          </dropdown-menu>
        </dropdown>
      </slot>
    </div>
  </div>
</template>

<script>
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui';
import { mapState } from 'vuex';
// utils
import { getHostUrlPath, getFileUrl } from '@/utils/common';
export default {
  name: 'commonHeader',

  components: {
    Dropdown,
    DropdownMenu,
    DropdownItem,
  },

  props: {
    bgColor: {
      type: String,
      default: '#242e45',
    },
    theme: {
      type: String,
      default: 'black', // black white
    },
  },

  data() {
    return {
      defaultLogoUrl: require('@images/components/header/logo.png'), // 默认logo
      defalutAvatarUrl: require('@images/components/header/default_avatar.png'), // 默认头像

      avatarUrl: '',
    };
  },

  computed: {
    ...mapState({
      lessonName: (state) => state.common.lessonName,
    }),
    userName() {
      return this.$store.getters.userInfo.name;
    },
  },

  methods: {
    handleClickRoute(type) {
      switch (type) {
        case 'user-info':
          // 路由跳转
          this.$router
            .push(`/user`)
            .then(() => {})
            .catch(() => {});
          break;
        case 'logout':
          // 退出登录
          this.$store.dispatch('user/logout').then(() => {
            location.replace(getHostUrlPath('/login'));
            // this.$router.push('/login');
          });
          break;
        default:
          break;
      }
    },
  },

  watch: {
    '$store.getters.userInfo': {
      handler: function (val) {
        if (val.photo) {
          this.avatarUrl = getFileUrl(val.photo);
        } else {
          this.avatarUrl = this.defalutAvatarUrl;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" src="./index.scss" scoped></style>
